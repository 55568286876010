import { render, staticRenderFns } from "./EditCurriculumLevel.vue?vue&type=template&id=36e98a29&scoped=true&"
import script from "./EditCurriculumLevel.vue?vue&type=script&lang=js&"
export * from "./EditCurriculumLevel.vue?vue&type=script&lang=js&"
import style0 from "./EditCurriculumLevel.vue?vue&type=style&index=0&id=36e98a29&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36e98a29",
  null
  
)

export default component.exports